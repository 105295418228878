<template>
  <div id="Lucky">
    <div class="Lucky_nav">
      <div
        class="Lucky_nav_item"
        v-for="(item, index) in TypeList"
        :key="index"
        :class="{ active: index == TypeIdx }"
        @click="ChangeType(index, item.id)"
      >
        <i></i>
        <img :src="item.cover" alt="" />
      </div>
    </div>
    <div class="Lucky_Serch">
      <input type="text" v-model="SerchData" />
      <van-button type="info" size="small" @click="GetSerch" class="searchBtn"
        >搜索装备</van-button
      >
    </div>
    <!--		:style="`background-image:url(${require('@/assets/images/Lucky/LV' + item.lv + '.png')})`"-->
    <div class="Lucky_list">
      <div
        class="Lucky_item"
        v-for="(item, index) in EquipmentList"
        :key="index"
        @click="ToOpen(item.id)"
      >
        <div class="Lucky_item_top">
          <p>
            <img src="@/assets/images/public/Gold.png" alt="" />{{ item.bean }}
          </p>
          <!-- <span>{{ item.dura_alias }}</span> -->
        </div>
        <div
          class="Lucky_item_pic"
          :style="{ 'background-image': 'url(' + item.lv_bg_image + ')' }"
        >
          <img :src="item.cover" alt="" />
        </div>
        <div class="Lucky_item_data">
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LuckyType, LuckyList, LuckySo } from "@/network/api.js";
export default {
  name: "Lucky",
  data() {
    return {
      TypeList: [], //类别列表
      EquipmentList: [], //装备列表
      TypeIdx: 0, //类别样式索引
      TypeId: 1, //类别id
      SerchData: "", //搜索关键字
    };
  },
  created() {
    this.GetLuckyType();
    this.GetLuckyList();
  },
  methods: {
    //装备Type列表
    GetLuckyType() {
      LuckyType().then((res) => {
        // console.log(res.data.data)
        this.TypeList = res.data.data;
      });
    },
    // 改变Type
    ChangeType(index, id) {
      this.TypeIdx = index;
      this.TypeId = id;
      this.GetLuckyList();
    },

    //装备搜索
    GetSerch() {
      LuckySo(this.SerchData).then((res) => {
        // console.log(res)
        this.EquipmentList = res.data.data;
      });
    },

    //装备列表
    GetLuckyList() {
      LuckyList(this.TypeId).then((res) => {
        // console.log(res.data.data)
        this.EquipmentList = res.data.data;
      });
    },

    //路由跳转开箱
    ToOpen(id) {
      this.$router.push({
        path: "/Lucky_open",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>

<style lang="scss">
#Lucky {
  padding: 5px;
  box-sizing: border-box;
  background: url('../../assets/images/q2/lucky/bac.png') no-repeat center;
  background-size: 100% 100%;
  background-attachment: fixed;
  .searchBtn{
    background: rgb(245, 164, 17) !important;
    color: fff !important;
    font-weight: 500;
    background: url('../../assets/images/q2/Backpack/right.png') no-repeat center !important;
    background-size: 110% 110% !important;
  }
   .Lucky_nav::-webkit-scrollbar {
      width: 2px;
      
    }
    .Lucky_nav::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow:  rgba(0, 0, 0, 0.2);
      background: rgba(255, 255, 255, 0.2);
    }
    // .Lucky_nav::-webkit-scrollbar-track {
    //   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    //   border-radius: 0;
    //   background: rgba(255, 255, 255, 0.1);
    // }
  
  .Lucky_nav {
    width: 100%;
    height: 110px;
    background: rgba(18, 28, 39, 0.8);
    border-radius: 4px;
    overflow-x: auto;
    white-space: nowrap;
    overflow-y: hidden;
    background: url('../../assets/images/q2/lucky/toptitle.png') no-repeat center;
    background-size: 100% 100%;
    padding-top: 20px;
    .Lucky_nav_item {
      display: inline-block;
      width: 70px;
      height: 70px;
      margin: 5px;
      padding: 5px;
      position: relative;
      line-height: 70px;
      text-align: center;
      // background: url(../../assets/images/public/pub_back_green.png);
      // padding: 10px;
      img {
        max-width: 90%;
        max-height: 90%;
        vertical-align: middle;
        filter: grayscale(1);
      }
      i {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%);
        transform: translateY(-50%);
        width: 0.02rem;
        content: "";
        height: 0.02rem;
        border-radius: 50%;
        background: none;
        box-shadow: none;
      }
      &.active {
        background-image: url("../../assets/images/topNav.png");
        background-size: 100% 100%;
        img {
          filter: none;
        }
        i {
          // background: rgba(67,42,192,.6);
          /*box-shadow: 0 0 35px 20px #ffd300;*/
        }
      }
    }
  }

  .Lucky_Serch {
    width: 100%;
    display: flex;
    margin-top: 10px;
    input {
      flex: 1;
      border-radius: 4px 0 0 4px;
      border: 1px solid rgb(245, 164, 17);
      background: rgba($color: rgb(245, 164, 17), $alpha: 0.2);
      color: #fff;
      padding: 0 20px;
      box-sizing: border-box;
    }
  }

  .Lucky_list {
    margin-top: 10px;
    padding-top: 10px;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
    display: flex;
    background: url('../../assets/images/q2/lucky/bottombac.png') no-repeat center;
    background-position:left top;
    background-size: 100% auto;
    background-color: rgba($color: #000000, $alpha: 0.7);
    // background-attachment: fixed;

    .Lucky_item {
      display: inline-block;
      width: 32%;
      border: 1px solid rgba(18, 28, 39, 0.8);
      padding: 10px 10px 10px;
      box-sizing: border-box;
      border-radius: 4px;
      margin-top: 10px;
      background-size: 100% 100%;
      background-color: #131426;
      background-size: 100% 100%;
      border-radius: 5px;
      // border: 1px solid rgb(210, 73, 73);
      // background: linear-gradient(to top, rgba(0, 0, 0, 0), rgb(133, 46, 46));
      background: url('../../assets/images/q2/lucky/2.png') no-repeat center;
      background-size:100% 100%;
      margin-left: 0.5%;
      .Lucky_item_top {
        width: 100%;
        display: flex;
        height: 20px;
        padding-left: 10px;
        padding-top: 5px;
        p {
          // width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: #f1bc60;

          img {
            width: 12px;
          }
        }

        span {
          display: block;
          width: 50%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 10px;
          color: #fff;
        }
      }

      .Lucky_item_pic {
        width: 80%;
        height: 50px;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
        background-size: 100% !important;
        background-position: center !important;
        background-repeat: no-repeat !important;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .Lucky_item_data {
        width: 100%;
        color: #fff;
        text-align: center;
        // margin-top: 10px;
        p {
          margin: 0 auto;
          width: 90%;
          font-size: 11px;
          line-height: 20px;
          margin-bottom: 4px;
          overflow: hidden;
          /* 第二步：让文本不会换行， 在同一行继续 */
          white-space: nowrap;
          /* 第三步：用省略号来代表未显示完的文本 */
          text-overflow: ellipsis;

        }
      }
    }
  }
}
</style>
